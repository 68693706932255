<template>
  <div>
    <h4 class="step-block-title">{{ title }}</h4>
    <div class="step-block">
      <div class="step-number">
        <i class="icon-location"></i>
        <span>{{ step.properties.num_step }}</span>
      </div>
      <div class="step-separator"></div>
      <div class="step-address">
        {{ stepAddress }}
      </div>
      <div @click="centerMap"
           class="step-center">
        <i class="icon-centermap"></i>
      </div>
      <div @click="deleteStep(step)"
           class="step-delete">
        <i class="icon-delete"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    authorization: {
      type: Object,
      required: true
    },
    step: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: false
    },
    isPreviousStep: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    title () {
      if (this.isPreviousStep) {
        return 'Point d\'intersection précédent'
      } else if (this.$route.name === 'authorizations.steps.edit') {
        return 'Ajustez le point sur la carte'
      } else {
        return 'Ajustez le point suivant sur la carte'
      }
    },
    stepAddress () {
      if (!this.step.properties.address) {
        return '--'
      }
      if (this.step.properties.address.includes(', France')) {
        return this.step.properties.address.split(', France')[0]
      }
      return this.step.properties.address
    }
  },
  methods: {
    centerMap () {
      if (this.map) {
        this.map.panTo([this.step.properties.latitude, this.step.properties.longitude])
      }
    },
    deleteStep (step) {
      if (this.$route.name === 'authorizations.steps.create-next' && !this.isPreviousStep) {
        this.$emit('remove-step-from-store', step)
      } else if (this.$route.params.name !== 'authorizations.steps.edit' || this.isPreviousStep) {
        // the step is already in the database
        this.$emit('delete-step-from-database', step)
      } else {
        // the step is not yet on the database
        this.$emit('remove-step-from-store', step)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .step-block-title {
    margin-top: 25px;
    margin-bottom: 25px;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 15px;
    }
  }

  .step-block {
    border: 1px solid #dde3e8;
    border-radius: 2px;
    padding: 14px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-lg) {
      padding: 12px 8px;
    }

    @media (max-width: $breakpoint-md) {
      padding: 10px 8px;
      margin-bottom: 20px;
    }

    i {
      color: $red;
      font-size: 18px;

      @media (max-width: $breakpoint-md) {
        font-size: 15px;
      }
    }
  }

  .step-number,
  .step-address,
  .step-center,
  .step-delete {
    height: 100%;
  }

  .step-number {
    font-size: 15px;
    width: 21%;
    text-align: center;

    @media (min-width: 1450px) {
      width: auto;
      min-width: 14%;
    }

    @media (max-width: $breakpoint-lg) {
      font-size: 13px;
      text-align: left;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
    }

    i {
      display: inline-block;
      margin-right: 3px;

      @media (max-width: $breakpoint-lg) {
        font-size: 14px;
      }

      @media (max-width: $breakpoint-md) {
        margin-right: 1px;
        font-size: 11px;
      }
    }
  }

  .step-separator {
    height: 35px;
    width: 1px;
    background: $red;
    margin-right: 5px;

    @media (max-width: $breakpoint-md) {
      height: 30px;
      margin-right: 8px;
    }
  }

  .step-address {
    font-size: 14px;
    width: 85%;

    @media (max-width: $breakpoint-lg) {
      font-size: 13px;
      width: 80%;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
    }
  }

  .step-center {
    margin-right: 5px;

    i {
      @media (max-width: $breakpoint-md) {
        font-size: 13px;
      }
    }
  }

  .step-delete i {
    @media (max-width: $breakpoint-lg) {
      font-size: 14px;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
    }
  }

  .step-center:hover,
  .step-delete:hover {
    cursor: pointer;
  }
</style>
