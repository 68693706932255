<template>
  <div class="steps-tabs">
    <div @click="drawSteps"
         :class="drawStepsClasses">
      Tracer les tronçons
    </div>
    <div @click="allItinerary"
         :class="allItineraryClasses">
      Tout l'itinéraire
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps
    }),
    drawStepsClasses () {
      return {
        'steps-tabs-item': true,
        active: [
          'authorizations.steps.create',
          'authorizations.steps.create-next',
          'authorizations.steps.edit'
        ].includes(this.$route.name)
      }
    },
    allItineraryClasses () {
      return {
        'steps-tabs-item': true,
        active: this.$route.name === 'authorizations.steps.index',
        'steps-tabs-item-disabled': this.steps.length === 0
      }
    }
  },
  methods: {
    drawSteps () {
      if (this.$route.name === 'authorizations.steps.create'
        || this.$route.name === 'authorizations.steps.create-next'
        || this.$route.name === 'authorizations.steps.edit') {
        return
      }
      if (this.steps.length) {
        // todo check if authorization is finalized (no create-next)
        this.$router.push({
          name: 'authorizations.steps.create-next',
          params: {
            id: this.$route.params.id,
            num_step: this.steps.length
          }
        })
      } else {
        this.$router.push({
          name: 'authorizations.steps.create',
          params: {
            id: this.$route.params.id
          }
        })
      }
    },
    allItinerary () {
      if (this.$route.name === 'authorizations.steps.index' || !this.steps.length) return
      this.$router.push({
        name: 'authorizations.steps.index',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .steps-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;

    @media (max-width: $breakpoint-md) {
      height: 40px;
    }
  }

  .steps-tabs-item {
    text-align: center;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0 5px;
    color: $gray-text-light;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    border-right: 1px solid #eee;

    @media (max-width: $breakpoint-md) {
      font-size: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      border-right: none;
    }

    &.active {
      color: $gray-text;
      border-bottom: 2px solid $red;
      &:hover {
        cursor: default;
      }
    }

    &.steps-tabs-item-disabled {
      &:hover {
        cursor: no-drop;
      }
    }
  }

</style>
