<template>
  <div class="step-details">
    <h4 class="step-details-title">Détails du point
                                   {{ step.properties.num_step }}</h4>
    <div v-if="step.properties.num_step > 1" class="form-group">
      <label style="margin-right: 10px;"
       >Itinéraire automatique jusqu'au point</label>
      <div>
      <label class="switch">
      <input v-model="use_ors"
             type="checkbox"
             name="use_ors"
             class="form-control">
      <span class="slider round"></span>
      </label></div>
    </div>
    <FormInput v-model="name"
               name="name"
               id="name"
               type="text"
               label="Nom du tronçon (autorisation Pdf)"/>
    <FormInput v-model="pageNumber"
               name="page_number"
               id="page_number"
               type="number"
               label="Page Pdf"/>
    <FormTextArea v-model="comment"
                  name="comment"
                  id="comment"
                  label="Prescriptions associées"
                  :rows="3"/>
  </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormTextArea from '@/components/FormTextArea'
import { mapActions } from 'vuex'
import { clone } from '@/utils'

export default {
  components: {
    FormInput,
    FormTextArea
  },
  props: {
    step: {
      type: Object,
      required: true
    }
  },
  computed: {
    use_ors: {
      get: function () {
        return this.step.properties.use_ors
      },
      set: function (newValue) {
        this.updateStepUseORS([newValue,
          clone(this.step.properties.num_step)])
      }
    },
    name: {
      get () {
        return this.step.properties.name
      },
      set (value) {
        this.modifyStepDetails({
          name: 'name',
          value,
          step: this.step
        })
      }
    },
    pageNumber: {
      get () {
        return this.step.properties.page_number
      },
      set (value) {
        this.modifyStepDetails({
          name: 'page_number',
          value,
          step: this.step
        })
      }
    },
    comment: {
      get () {
        return this.step.properties.comment
      },
      set (value) {
        this.modifyStepDetails({
          name: 'comment',
          value,
          step: this.step
        })
      }
    }
  },
  methods: {
    ...mapActions({
      modifyStepDetails: 'authorization/modifyStepDetails',
      updateStepUseORS: 'authorization/updateStepUseORS'
    })
  }
}
</script>

<style scoped lang="scss">
  .step-details {
    border: 1px solid #dde3e8;
    border-radius: 2px;
    margin-bottom: 45px;
    padding: 30px 20px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);

    @media (max-width: $breakpoint-md) {
      padding: 20px 15px;
    }
  }

  .step-details-title {
    margin-bottom: 25px;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #f9124f;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

</style>
